@import '../../Global/Defaults.sass'

.main-container
    display: flex
    // width: 10vw
    // position: relative


.sidebar-container 
    display: flex
    flex-direction: column
    align-items: center 
    justify-content: flex-start
    position: fixed
    height: 100vh
    width: 15vw
    margin:0
    background-color: $primary-color

.logo-info 
    display: flex
    flex-direction: column
    align-items: center 
    justify-content: center
    height: 20vh
    width: 25vw

    img
        height: 2.5em
        width: 8em

.menu-list
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start

.list
    display: flex
    margin: 0 0 2em 1em
    padding-left: 1em 
    align-items: center
    justify-content: flex-start
    background-color: $primary-color
    text-decoration: none !important
    width: 13vw
    .icon svg
        height: 1.5em
        width: 2em
        margin: 0 1em 0 0

    .link_text
    font-size: 15px
    color: $secondary-color

.list:hover 
    transform: scale(1.01)
    transition: 1s ease-in-out .5s
.active
    background: $secondary-color
    color: $primary-color
    opacity: 0.4
    font-weight: bold
    border-radius: 0.5em
    height: 2.5em
    width: 15vw

main
    // position: relative
    // width: 75vw
    display: flex
    // width: 75vw
    height: 100vh
    padding: 20px
    margin-left: 15vw
    background-color: $secondary-color

.logout-btn
    display: flex
    align-items: center
    justify-content: center
    width: 15vw

.logout-btn:hover
    background: 'red'
