.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.discussion-container {
  overflow-y: scroll;
  margin-right: 2em;
  overflow: hidden;
}/*# sourceMappingURL=AddDiscussion.css.map */