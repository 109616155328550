.floating-button-container
  display: flex
  gap: 10px

.floating-add-button-1 
  display: block
  margin: 10px
  position: fixed
  bottom: 15px
  right: 50px
  z-index: 1000
  font-size: 30rem
  border-radius: 100%
  font-size: 2rem
  padding: 0.3em 0.2em
  line-height: 1
  width: 80px
  height: 80px
  opacity: 0.6
  margin: 2px

  &:hover 
    opacity: 1

.floating-add-button-2 
  display: block
  margin: 10px
  position: fixed
  bottom: 15px
  right: 140px
  z-index: 1000
  font-size: 30rem
  border-radius: 100%
  font-size: 2rem
  padding: 0.3em 0.2em
  line-height: 1
  width: 80px
  height: 80px
  opacity: 0.6
  margin: 2px

  &:hover 
    opacity: 1