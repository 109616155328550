.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.discussion-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 225vh;
  width: 80vw;
}
.discussion-container .discussion-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.discussion-container .btn-add:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.nav-tabs .nav-link.active {
  font-weight: bold;
  font-size: 1.25em;
  color: #292929;
}

.nav-link {
  color: #292929;
}/*# sourceMappingURL=Discussion.css.map */