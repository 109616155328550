.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.banner-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 125vh;
  width: 80vw;
}
.banner-container .banner-title {
  display: flex;
  flex-direction: row;
}
.banner-container .btn-add {
  margin: 2em;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
  font-size: 1.25em;
  color: #292929;
}

.nav-link {
  color: #292929;
}/*# sourceMappingURL=Banner.css.map */