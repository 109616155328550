@import '../../Global/Defaults.sass'

.reports-container
    display: flex
    flex-direction: row
    align-items: flex-start
    height: 145vh
    width: 80vw

    .reports-title
        display: flex
        flex-direction: row
    .btn-add
        margin: 2em

    .btn-add:hover
        opacity: 0.50
        transform: scale(1.1)

.nav-tabs .nav-link.active
    font-weight: bold
    font-size: 1.25em
    color: $primary-color

.nav-link
    color: $primary-color