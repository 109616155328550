.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.discussion-container {
  overflow-y: scroll;
  margin-right: 2em;
}

svg {
  height: 1.5em;
  width: 2em;
  margin: 0 1em 0 0;
}

svg:hover {
  opacity: 0.25;
  cursor: pointer;
}/*# sourceMappingURL=UpdateDiscussion.css.map */