.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.page-title h5 {
  font-size: 1em;
  color: #8A8A8A;
}
.page-title h1 {
  width: 5em;
}/*# sourceMappingURL=PageTitle.css.map */