@import '../../../Global/Defaults.sass'

.discussion-container
    overflow-y: scroll
    margin-right: 2em

svg
    height: 1.5em
    width: 2em
    margin: 0 1em 0 0

svg:hover
    opacity: 0.25
    cursor: pointer