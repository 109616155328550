@import '../../../Global/Defaults'

.searchDivStyle
        margin: 1em
        background-color: $secondary-color
        padding: 10
        border-radius: 0em
        width: 77vw

        .searchStyle
            display: inline-flex
            width: 50vw
            padding: 0.5em 1em
            border-radius: 1em
            outline: 0
            border: 0.1em $primary-color solid
            font-size: 1em
.bg-table 
    // display: flex
    // justify-content: center
    // align-items: flex-start
    height: 78vh
    width: 80vw
    background-color: $primary-color
    border-radius: 1em
    padding: 1em
    margin: 0 0 2em 0
h2
    font-size: 1.5em
    margin: 1em 0 1em 0
    color: $secondary-color

.ag-root-wrapper
    border-radius: 1em

.ag-theme-alpine
    height: 65vh
    width: 90vw
