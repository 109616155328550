
$font-stack: Helvetica, sans-serif
$primary-color: #292929
$secondary-color: #FFFFFF
$tertiary-color: #D9D9D9
$active-bg-color: #1E1E1E
$sub-title-color: #8A8A8A
$link-hover-color: #223B6A

.primary-btn 
    margin: 2em
    varient: 'warning'

.primary--btn:hover
        opacity: 0.50
        transform: scale(1.1)