@import '../../Global/Defaults.sass'

.user-container
    display: flex
    flex-direction: row
    align-items: flex-start
    height: 110vh
    width: 80vw
    .user-title
        display: flex
        flex-direction: row

    .btn-add
        margin: 2em
