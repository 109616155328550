@import '../../Global/Defaults.sass'

.page-title
    // display: flex

    // header: 2em
    // width: 1em
    h5
        font-size: 1em
        color: $sub-title-color
    h1
        width: 5em
        
