/* @import '../../Global/Defaults.css'; */

.lz_dashboard {
  /* padding: 20px; */
  padding-bottom: 150px;
}

.lz_filters {
  display: flex;
  flex-direction: row;
  align-items: center; /* Ensures button aligns with date inputs */
  margin-bottom: 20px;
  justify-content:space-between;
  width: 100%;
}

.lz_filters .lz_btn {
  margin-left: 10px; /* Add space between the button and the date inputs */
}

.lz_table {
  margin-bottom: 20px;
}

.lz_pagination {
  display: flex;
  justify-content: center;
}

.lz_loader {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

/* Restrict the width of the pagination container */
.pagination-container {
  max-width: 100%;    /* Ensures pagination stays within parent container */
  overflow-x: auto;   /* Allows horizontal scrolling */
  white-space: nowrap; /* Prevents pagination from wrapping onto multiple lines */
}

/* Optional: Add styling to make the scrollbar look better */
.pagination-container::-webkit-scrollbar {
  height: 8px;
}

.pagination-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}


.shortener-page {
  width: 80vw;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.create-link-tab {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.custom-link-section {
  display: flex;
  align-items: center;
}

.availability-check {
  margin-left: 10px;
  color: green;
}

/* button {
  color: #fff;
}

button:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
} */


/* Ensure the table takes full width */
.table {
  width: 80vw;
  table-layout: fixed;  /* Forces table to respect widths of columns */
}

.table th, .table td {
  padding: 8px;
  white-space: break-word;  /* Prevents other columns from wrapping */
  /* text-overflow: ellipsis;  */
  overflow: hidden;     /* Hides overflow content */
}

.long-url-column {
  white-space: normal;    /* Allows text wrapping */
  word-wrap: break-word;  /* Allows URLs to break into new lines */
  /* width: 100%;             */
  max-width: 100%;        /* Prevents overflow beyond table width */
}