.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.user-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 110vh;
  width: 80vw;
}
.user-container .user-title {
  display: flex;
  flex-direction: row;
}
.user-container .btn-add {
  margin: 2em;
}/*# sourceMappingURL=User.css.map */