.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.bg1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 85vh;
  width: 80vw;
  background-color: #292929;
  border-radius: 1em;
  padding: 1em;
  margin: 0 0 2em 0;
}

h2 {
  font-size: 1.5em;
  margin: 1em 0 1em 0;
  color: #FFFFFF;
}

.bg2 {
  height: 80vh;
  width: 75vw;
  border-radius: 1em;
  background-color: #FFFFFF;
}/*# sourceMappingURL=Report.css.map */