.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: scroll;
    width: 80vw;
}

.dropdown {

    position: relative;
    width: 100%;
    max-width: 300px;
}

.dropdown .search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dropdown select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.dropdown .hover-banner {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f9f9f9;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
}

  
.stats-display {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(1, minmax(400px, 1fr)); */
    gap: 20px;
}

.stats-display .stat-item {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.stats-display .stat-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.stats-display .stat-item p {
    margin: 4px 0;
}

.stats-display .stat-item .charts {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 75vw;
}
  
.lz_filters1 {
    flex-direction: row;
    align-items: center;
    width: 300px;
    /* display: flex; */
}
