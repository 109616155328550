@import '../../Global/Defaults'


.bg1
    display: flex
    justify-content: center
    align-items: flex-start
    height: 85vh
    width: 80vw
    background-color: $primary-color
    border-radius: 1em
    padding: 1em
    margin: 0 0 2em 0
h2
    font-size: 1.5em
    margin: 1em 0 1em 0
    color: $secondary-color
    
.bg2
    height: 80vh
    width: 75vw
    border-radius: 1em
    background-color: $secondary-color
