// LiveUsersCard.sass

@keyframes glowing
  0%
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.8), 0 0 20px rgba(0, 123, 255, 0.8)
  50%
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.8), 0 0 30px rgba(0, 123, 255, 0.6)
  100%
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.8), 0 0 20px rgba(0, 123, 255, 0.8)

.live-users-card
  animation: glowing 2s infinite
