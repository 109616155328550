.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.searchDivStyle {
  margin: 1em;
  background-color: #FFFFFF;
  padding: 10;
  border-radius: 0em;
  width: 77vw;
}
.searchDivStyle .searchStyle {
  display: inline-flex;
  width: 50vw;
  padding: 0.5em 1em;
  border-radius: 1em;
  outline: 0;
  border: 0.1em #292929 solid;
  font-size: 1em;
}

.bg-table {
  height: 78vh;
  width: 80vw;
  background-color: #292929;
  border-radius: 1em;
  padding: 1em;
  margin: 0 0 2em 0;
}

h2 {
  font-size: 1.5em;
  margin: 1em 0 1em 0;
  color: #FFFFFF;
}

.ag-root-wrapper {
  border-radius: 1em;
}

.ag-theme-alpine {
  height: 65vh;
  width: 90vw;
}/*# sourceMappingURL=Table.css.map */