.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.btn-add:hover {
  opacity: 0.5;
  transform: scale(1.1);
}/*# sourceMappingURL=FeedTags.css.map */