.primary-btn {
  margin: 2em;
  varient: "warning";
}

.primary--btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.main-container {
  display: flex;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  height: 100vh;
  width: 15vw;
  margin: 0;
  background-color: #292929;
}

.logo-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  width: 25vw;
}
.logo-info img {
  height: 2.5em;
  width: 8em;
}

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.list {
  display: flex;
  margin: 0 0 2em 1em;
  padding-left: 1em;
  align-items: center;
  justify-content: flex-start;
  background-color: #292929;
  text-decoration: none !important;
  width: 13vw;
  font-size: 15px;
  color: #FFFFFF;
}
.list .icon svg {
  height: 1.5em;
  width: 2em;
  margin: 0 1em 0 0;
}
.list:hover {
  transform: scale(1.01);
  transition: 1s ease-in-out 0.5s;
}

.active {
  background: #FFFFFF;
  color: #292929;
  opacity: 0.4;
  font-weight: bold;
  border-radius: 0.5em;
  height: 2.5em;
  width: 15vw;
}

main {
  display: flex;
  height: 100vh;
  padding: 20px;
  margin-left: 15vw;
  background-color: #FFFFFF;
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
}

.logout-btn:hover {
  background: "red";
}/*# sourceMappingURL=Sidebar.css.map */