.primary-btn {
  display: "flex";
  margin: 2em;
  min-width: 8em;
  max-width: 10em;
}

.primary-btn:hover {
  opacity: 0.5;
  transform: scale(1.1);
}/*# sourceMappingURL=CustomButton.css.map */